<template>
  <div>
    <v-row>
      <v-col md="12">
        <v-card elevation="10">
          <v-toolbar
            dense
            color="#003d6a"
            elevation="0"
            dark
            style="z-index: 9"
          >
            <v-toolbar-title>Partnerler</v-toolbar-title>
            <v-spacer></v-spacer>

            <div class="d-flex justify-content-end" style="min-width: 550px">
              <input
                type="text"
                v-model="searchFilterInput"
                class="form-control mr-3"
                style="max-width: 250px"
                autocomplete="new"
                placeholder="Arama..."
                v-on:keyup="onFilterTextBoxChanged()"
              />
              <RoleProvider slug="PARTNER_CREATE">
                <div slot-scope="{ canItPass }">
                  <CButton
                    color="light"
                    variant="outline"
                    v-if="canItPass"
                    size="sm"
                    class="py-2 px-3 font-weight-bold"
                    @click="clickOpenModalButton(`new`)"
                  >
                    <font-awesome-icon
                      :icon="['fas', 'plus']"
                      class="mr-2"
                    />Yeni Ekle
                  </CButton>
                </div>
              </RoleProvider>
            </div>
          </v-toolbar>

          <CCardBody class="p-0">
            <ag-grid-vue
              style="width: 100%; height: calc(100vh - 145px)"
              class="ag-theme-balham fp-table"
              :columnDefs="columnDefs"
              :defaultColDef="defaultColDef"
              :rowData="rowData"
              :sideBar="sideBar"
              :rowSelection="rowSelection"
              :enableRangeSelection="true"
              :statusBar="statusBar"
              @rowDoubleClicked="
                (params) => clickOpenModalButton(`old`, params)
              "
              @grid-ready="onGridReady"
            ></ag-grid-vue>
          </CCardBody>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      style="z-index: 1031 !important"
      v-model="modal.show"
      eager
      max-width="1100"
    >
      <v-card class="p-0 m-0 cardShadow">
        <v-toolbar dense dark color="primary" fixed>
          <v-toolbar-title>{{ modal.title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="modal.show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-form ref="partnerForm" @submit.prevent="addOrUpdateAxios">
          <div class="p-4 m-0">
            <v-row>
              <v-col>
                <v-select
                  v-model="form.status"
                  placeholder="Durum Seçiniz"
                  label="Durum"
                  :items="constants.statusOptions"
                  item-text="label"
                  item-value="value"
                  :rules="[rules.required]"
                  outlined
                  dense
                  hide-details
                />
              </v-col>
              <v-col>
                <v-text-field
                  outlined
                  hide-details=""
                  dense
                  type="text"
                  v-model="form.title"
                  placeholder="Firma Ünvanı"
                  label="Firma Ünvanı"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col>
                <v-text-field
                  outlined
                  hide-details=""
                  dense
                  type="text"
                  v-model="form.phone"
                  placeholder="Telefon"
                  label="Telefon"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col>
                <v-text-field
                  outlined
                  hide-details=""
                  dense
                  type="text"
                  v-model="form.email"
                  placeholder="E-Posta"
                  label="E-Posta"
                  :rules="[rules.email]"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                  outlined
                  hide-details=""
                  dense
                  type="text"
                  v-model="form.brand"
                  placeholder="Firma Markası"
                  label="Firma Markası"
                  :rules="[rules.required]"
                />
              </v-col>

              <v-col>
                <v-text-field
                  outlined
                  hide-details=""
                  dense
                  type="text"
                  v-model="form.taxOffice"
                  placeholder="Vergi Dairesi"
                  label="Vergi Dairesi"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col>
                <v-text-field
                  outlined
                  hide-details=""
                  dense
                  type="text"
                  v-mask="'#########################'"
                  v-model="form.taxNumber"
                  placeholder="Vergi Numarası"
                  label="Vergi Numarası"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col>
                <v-text-field
                  outlined
                  hide-details=""
                  dense
                  type="text"
                  v-model="form.mersisNumber"
                  placeholder="Mersis Numarası"
                  label="Mersis Numarası"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  outlined
                  hide-details=""
                  dense
                  v-model="form.address"
                  :rules="[rules.required]"
                  placeholder="Adres"
                  label="Adres"
                  rows="2"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <v-text-field
                  outlined
                  hide-details=""
                  dense
                  v-model="form.creditLimit"
                  placeholder="Cari Kredi Limiti"
                  label="Cari Kredi Limiti"
                  type="number"
                  step="0.01"
                  min="0"
                  max="100"
                />
              </v-col>
              <v-col cols="3">
                <v-text-field
                  outlined
                  hide-details=""
                  dense
                  v-model="form.coverageAmount"
                  placeholder="Teminat Bedeli"
                  label="Teminat Bedeli"
                  type="number"
                  step="0.01"
                  min="0"
                  max="100"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-card elevation="10" class="pa-4 mt-0">
                  <label
                    ><strong>Rezervasyon Kuralları</strong>
                    <small
                      >Partner sadece aşağıdaki çalışma kurallarını sağlayan
                      tedarikçilere rezervasyon geçebilir.</small
                    ></label
                  >
                  <v-row>
                    <v-col cols="2">
                      <v-switch
                        hide-details=""
                        label="Normal"
                        v-model="form.reservationTypes.normal"
                      />
                    </v-col>
                    <v-col cols="2">
                      <v-switch
                        hide-details=""
                        label="Full Credit"
                        v-model="form.reservationTypes.fullCredit"
                      />
                    </v-col>
                    <v-col cols="2">
                      <v-switch
                        hide-details=""
                        label="Ofiste Öde"
                        v-model="form.reservationTypes.payOnArrival"
                      />
                    </v-col>
                    <v-col cols="2">
                      <v-switch
                        hide-details=""
                        label="Parçalı Öde"
                        v-model="form.reservationTypes.partialPayment"
                      />
                    </v-col>
                    <v-divider vertical class="mx-3" />
                    <v-col cols="3">
                      <v-checkbox
                        dense
                        hide-details=""
                        label="Üzerine Satış"
                        hint="Partnerler gelen fiyatın üzerine koyup daha pahalıya satabilirler."
                        v-model="form.overSelling"
                      />
                      <small
                        >Partnerler fiyatın üzerine kar koyup
                        satabilirler.</small
                      >
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card elevation="10" class="pa-4">
                  <label
                    ><strong>Firma Komisyonu (%)</strong>
                    <small
                      >(Net fiyat üzerinden partnere verilmiş komisyon
                      oranıdır.)</small
                    ></label
                  >
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        hide-details=""
                        dense
                        label="Günlük Kiralama"
                        type="number"
                        step="0.1"
                        min="-100"
                        max="100"
                        v-model="form.commissionRate.daily"
                      />
                    </v-col>

                    <v-col cols="6">
                      <v-text-field
                        hide-details=""
                        dense
                        label="Aylık Kiralama"
                        type="number"
                        step="0.1"
                        min="-100"
                        max="100"
                        v-model="form.commissionRate.monthly"
                      />
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>

              <v-col cols="6">
                <v-card elevation="10" class="pa-4">
                  <label
                    ><strong>Fiyatı Yükselt/Düşür (%)</strong>
                    <small>(Fiyatlarının üzerine kar ekler.)</small></label
                  >
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        hide-details=""
                        dense
                        label="Günlük Kiralama"
                        type="number"
                        step="0.1"
                        min="-100"
                        max="100"
                        v-model="form.priceManipulation.daily"
                      />
                    </v-col>

                    <v-col cols="6">
                      <v-text-field
                        hide-details=""
                        dense
                        label="Aylık Kiralama"
                        type="number"
                        step="0.1"
                        min="-100"
                        max="100"
                        v-model="form.priceManipulation.monthly"
                      />
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-card elevation="10" class="pa-4 mt-0">
                  <label><strong>Finans Parametreleri</strong> </label>

                  <v-row>
                    <v-col cols="3">
                      <v-select
                        v-model="form.defaultCurrency"
                        placeholder="Durum Seçiniz"
                        label="Varsayılan Para Birimi"
                        :items="constants.defaultCurrency"
                        :rules="[rules.required]"
                        item-text="label"
                        item-value="value"
                        dense
                        hide-details
                      />
                    </v-col>
                    <v-col cols="3">
                      <v-select
                        v-model="form.defaultLang"
                        placeholder="Durum Seçiniz"
                        label="Varsayılan Dil"
                        :items="constants.defaultLang"
                        :rules="[rules.required]"
                        item-text="label"
                        item-value="value"
                        dense
                        hide-details
                      />
                    </v-col>
                    <v-col cols="3">
                      <v-select
                        v-model="form.subSite"
                        placeholder="Alt Site Seç"
                        label="Alt Site"
                        :items="constants.subSites"
                        item-text="label"
                        item-value="value"
                        dense
                        hide-details
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <p>Ödeme Türleri</p>
                      <CInputCheckbox
                        v-for="(
                          option, optKey
                        ) in constants.typesOfPaymentAvailableOptions"
                        :key="option + optKey"
                        :inline="true"
                        :label="option.label"
                        :value="option.value"
                        @update:checked="checkBoxOnChange(option.value)"
                        :checked="
                          form.typesOfPaymentAvailable.includes(option.value)
                        "
                      />
                    </v-col>
                  </v-row>
                </v-card> </v-col
            ></v-row>

            <v-row>
              <v-col cols="12">
                <v-card elevation="10" class="pa-4 mt-0">
                  <label
                    ><strong>Sadece Seçili Tedarikçiler Listelensin</strong
                    ><br />
                    <small class="text-danger"
                      >DİKKAT!!! Bu alanı sadece belirlenen tedarikçilerin
                      listelenmesi için doldurun. Tüm tedarikçilerin araçlarını
                      listeleyebilmesi için BOŞ bırakın.</small
                    >
                  </label>
                  <v-row>
                    <v-col cols="12">
                      <v-autocomplete
                        v-model="form.workingVendors"
                        placeholder="Tedarikçiler"
                        label="Tedarikçiler"
                        :items="constants.workingVendors"
                        item-text="label"
                        item-value="value"
                        @change="selectedVendorSort()"
                        dense
                        chips
                        small-chips
                        hide-details
                        multiple
                      />
                    </v-col>
                  </v-row>
                </v-card> </v-col
            ></v-row>
            <v-row>
              <v-col cols="12">
                <v-card elevation="10" class="pa-5 mt-0">
                  <label><strong>Diğer Ayarlar</strong> </label>
                  <v-row>
                    <v-col cols="3">
                      <v-select
                        :items="sendMailItems"
                        item-text="tr"
                        item-value="val"
                        hide-details=""
                        label="Sürücüye Email Gönder"
                        v-model="form.sendMailToDriver"
                      />
                    </v-col>
                    <v-col cols="3">
                      <v-select
                        :items="sendSmsItems"
                        item-text="tr"
                        item-value="val"
                        hide-details=""
                        label="Sürücüye SMS Gönder"
                        v-model="form.sendSmsToDriver"
                      />
                    </v-col>
                    <v-col cols="3" class="d-flex justify-center">
                      <v-switch
                        hide-details=""
                        dense
                        label="Otomatik Fatura"
                        v-model="form.autoInvoice"
                      />
                    </v-col>

                    <v-col cols="3">
                      <v-select
                        v-model="form.partnerType"
                        placeholder="Partner Tipi Seçiniz"
                        label="Partner Tipi Seçiniz"
                        :items="constants.defaultPartnerTypes"
                        :rules="[rules.required]"
                        item-text="label"
                        item-value="value"
                        hide-details
                      />
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-card elevation="10" class="pa-4 mt-0" dense>
                  <label><strong>iFrame Partner Sitesi</strong> </label>
                  <v-row dense>
                    <v-col>
                      <v-switch
                        hide-details=""
                        label="Site Durumu"
                        v-model="form.isIframe"
                      />
                    </v-col>
                  </v-row>

                  <div v-if="showIframeConfig">
                    <v-row>
                      <v-col cols="6">
                        <v-text-field
                          dense
                          v-model="form.iframeInfo.iFrameUrl"
                          label="Subdomain URL"
                          persistent-hint
                          hint="Partner sitesinin sonu renticar.com/ ile biten adresi. / ile bitmelidir."
                          placeholder="https://*.renticar.com/"
                        />
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          dense
                          v-model="form.iframeInfo.logoUrl"
                          label="Logo URL"
                          persistent-hint
                          hint="Partnere ait logo url adresi'"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="4">
                        <v-card elevation="5">
                          <v-card-subtitle>Primary Color</v-card-subtitle>
                          <v-color-picker
                            dot-size="16"
                            label="Renkler"
                            hide-canvas
                            mode="hexa"
                            show-swatches
                            swatches-max-height="200"
                            v-model="form.iframeInfo.primaryColor"
                          ></v-color-picker>
                        </v-card>
                      </v-col>
                      <v-col cols="4">
                        <v-card elevation="5">
                          <v-card-subtitle>Secondary Color</v-card-subtitle>
                          <v-color-picker
                            dot-size="16"
                            hide-canvas
                            mode="hexa"
                            show-swatches
                            swatches-max-height="200"
                            v-model="form.iframeInfo.secondaryColor"
                          ></v-color-picker>
                        </v-card>
                      </v-col>
                      <v-col cols="4">
                        <v-card elevation="5">
                          <v-card-subtitle>Info Color</v-card-subtitle>
                          <v-color-picker
                            dot-size="16"
                            hide-canvas
                            mode="hexa"
                            show-swatches
                            swatches-max-height="200"
                            v-model="form.iframeInfo.infoColor"
                          ></v-color-picker>
                        </v-card>
                      </v-col>
                      <v-col cols="12" class="mt-6">
                        <v-text-field
                          dense
                          v-model="form.iframeInfo.backgroundImgUrl"
                          label="Background Image URL"
                          persistent-hint
                          placeholder="https://*.renticar.com/"
                        />
                      </v-col>
                    </v-row>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-form>

        <v-card-actions>
          <v-spacer></v-spacer>
          <RoleProvider slug="PARTNER_DELETE">
            <div slot-scope="{ canItPass }">
              <v-btn
                @click="deletePartnerAxios"
                color="error"
                class="mr-4"
                v-if="modal.action == `put` && canItPass"
                >SİL</v-btn
              >
            </div>
          </RoleProvider>
          <RoleProvider
            :slug="modal.action == `post` ? `PARTNER_CREATE` : `PARTNER_UPDATE`"
          >
            <div slot-scope="{ canItPass }">
              <v-btn
                @click="addOrUpdateAxios"
                v-if="canItPass"
                color="success"
                >{{ modal.action === "post" ? "EKLE" : "DEĞİŞTİR" }}</v-btn
              >
            </div>
          </RoleProvider>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import { AgGridVue } from "ag-grid-vue";
import VueSweetalert2 from "vue-sweetalert2";
import { RoleProvider } from "../../provider";
import { partnersColumnsDefs } from "../../columnDefs";
import { IMaskComponent } from "vue-imask";

var _ = require("lodash");
Vue.use(VueSweetalert2);
const formInitialState = () => ({
  _id: "",
  address: "",
  email: "",
  mersisNumber: "",
  phone: "",
  title: "",
  taxOffice: "",
  taxNumber: "",
  brand: "",
  typesOfPaymentAvailable: [],
  creditLimit: "",
  status: "",
  commissionRate: {
    daily: 0,
    monthly: 0,
  },

  priceManipulation: {
    daily: 0,
    monthly: 0,
  },
  reservationTypes: {
    normal: false,
    fullCredit: false,
    partialPayment: false,
    payOnArrival: false,
  },
  coverageAmount: "",
  subSite: "",
  workingVendors: [],
  sendMailToDriver: "",
  sendSmsToDriver: "",
  partnerType: "partner",
  autoInvoice: true,
  isIframe: false,
  iframeInfo: {
    primaryColor: null,
    secondaryColor: null,
    infoColor: null,
    iFrameUrl: null,
    logoUrl: null,
    backgroundImgUrl: null,
  },
});

export default {
  name: "Partners",
  components: {
    AgGridVue,
    RoleProvider,
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Zorunlu alan.",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Geçersiz e-mail";
        },
      },
      searchFilterInput: null,
      columnDefs: partnersColumnsDefs(this.$store.state.currency),
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true,
      },
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Sütunlar",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          {
            id: "filters",
            labelDefault: "Filtreler",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
        defaultToolPanel: "",
      },
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left",
            text: "asdas",
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center",
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" },
        ],
      },
      rowSelection: "multiple",
      rowData: [],
      showIframeConfig: false,
      sendMailItems: [
        {
          tr: "Tutarlı Mail Gönder",
          en: "Send consistent mail",
          val: "withPrice",
        },
        {
          tr: "Tutarsız Mail Gönder",
          en: "Send inconsistent mail",
          val: "noPrice",
        },
        {
          tr: "Mail Gönderme",
          en: "Do not send mail",
          val: "none",
        },
      ],
      sendSmsItems: [
        {
          tr: "Sms Gönder",
          en: "Send sms",
          val: "send",
        },
        {
          tr: "Sms Gönderme",
          en: "Do not send sms",
          val: "none",
        },
      ],

      form: {
        _id: "",
        address: "",
        email: "",
        mersisNumber: "",
        phone: "",
        title: "",
        taxOffice: "",
        taxNumber: "",
        brand: "",
        typesOfPaymentAvailable: [],
        status: "",
        creditLimit: "",
        commissionRate: {
          daily: 0,
          monthly: 0,
        },

        priceManipulation: {
          daily: 0,
          monthly: 0,
        },
        reservationTypes: {
          normal: false,
          fullCredit: false,
          partialPayment: false,
          payOnArrival: false,
        },
        coverageAmount: "",
        subSite: "",
        workingVendors: [],
        sendMailToDriver: "",
        sendSmsToDriver: "",
        partnerType: "partner",
        autoInvoice: true,
        isIframe: false,
        iframeInfo: {
          primaryColor: null,
          secondaryColor: null,
          infoColor: null,
          iFrameUrl: null,
          logoUrl: null,
          backgroundImgUrl: null,
        },
      },
      constants: {
        subSites: [
          {
            value: "no",
            label: "Yok",
          },
          {
            value: "turkeiMietWagen",
            label: "TurkeiMietWagen",
          },
        ],
        defaultCurrency: [
          {
            value: "TRY",
            label: "TRY",
          },
          {
            value: "USD",
            label: "USD",
          },
          {
            value: "EUR",
            label: "EUR",
          },
        ],
        defaultLang: [
          {
            value: "tr",
            label: "Türkçe",
          },
          {
            value: "en",
            label: "İngilizce",
          },
        ],
        statusOptions: [
          {
            value: "Active",
            label: "Aktif",
          },
          {
            value: "InActive",
            label: "Pasif",
          },
          {
            value: "Pending",
            label: "Bekliyor",
          },
          {
            value: "Banned",
            label: "Engellendi",
          },
        ],
        commistionOptions: [
          {
            value: false,
            label: "Yok",
          },
          {
            value: true,
            label: "Var",
          },
        ],
        workingVendors: [],
        typesOfPaymentAvailableOptions: [
          { label: "Sanal Pos", value: "noneSecurePos" },
          // { label: "ivrPos", value: "ivrPos" },// sonradan gelecek
          { label: "SMS Linki İle Ödeme", value: "smsPos" },
          { label: "Cari Hesap", value: "debit" },
        ],
        defaultPartnerTypes: [
          {
            value: "partner",
            label: "Partner",
          },
          {
            value: "corporate",
            label: "Kurumsal",
          },
          {
            value: "agent",
            label: "Acente",
          },
          {
            value: "replacement",
            label: "İkame",
          },
          {
            value: "overseasBroker",
            label: "Yurt Dışı Broker",
          },
          {
            value: "domesticBroker",
            label: "Yurt İçi Broker",
          },
        ],
      },
      modal: {
        action: "",
        title: "",
        show: false,
      },
    };
  },
  watch: {
    "form.isIframe": function (value) {
      this.showIframeConfig = value;
    },
  },
  created() {
    this.getPartners();
    this.getVendors();
  },
  methods: {
    selectedVendorSort() {
      this.form.workingVendors = this.form.workingVendors.sort((a, b) =>
        a.localeCompare(b)
      );
    },
    async addOrUpdateAxios() {
      const isValid = await this.$refs.partnerForm.validate();
      if (!isValid) {
        return;
      }
      //request type
      const requestType = this.modal.action;
      this.form.coverageAmount = Number(this.form.coverageAmount);
      this.form.creditLimit = Number(this.form.creditLimit);
      if (!this.isIframe) {
        delete this.form.infoColor;
        delete this.form.primaryColor;
        delete this.form.secondaryColor;
        delete this.form.iFrameUrl;
        delete this.form.backgroundImgUrl;
      }

      //if action post delete _id
      if (requestType == "post") delete this.form._id;
      try {
        const { data } = await axios[requestType](
          process.env.VUE_APP_API_URL + "admin/partner",
          this.form
        );
        this.alert("Başarılı", "success");
        if (requestType == "post") this.rowData.push(data);
        else this.getPartners();
        // this.resetWindow();
        this.modal.show = false;
      } catch (error) {
        console.log(error);
        this.alert("Lütfen bilgileri kontrol ediniz.", "error");
        this.modal = {
          action: "post",
          title: "Yeni Partner Ekle",
          show: true,
        };
      } finally {
        this.$Progress.finish();
      }
    },
    async deletePartnerAxios() {
      const body = { data: { ids: [this.form._id] } };
      try {
        await axios.delete(process.env.VUE_APP_API_URL + "admin/partner", body);
        this.alert("Başarılı", "success");
        this.modal.show = false;
        this.getPartners();
      } catch (error) {
        this.alert("Lütfen bilgileri kontrol ediniz.", "error");
      } finally {
        this.$Progress.finish();
      }
    },
    async getPartners() {
      this.$Progress.start();
      try {
        const { data } = await axios.get(
          process.env.VUE_APP_API_URL + "admin/partner"
        );
        this.rowData = _.reverse(data);
      } catch (error) {
        this.alert("Bir sorun oluştu");
      } finally {
        this.$Progress.finish();
      }
    },
    getVendors() {
      axios
        .get(process.env.VUE_APP_API_URL + "admin/vendor?status=true")
        .then((response) => {
          var array = response.data.vendorList;
          this.vendorList = response.data.vendorList;
          var result = [];
          for (let index = 0; index < array.length; index++) {
            const item = {
              label: array[index].brand,
              value: array[index].brandSlug,
            };
            result.push(item);
          }
          this.constants.workingVendors = result.sort((a, b) =>
            a.label.localeCompare(b.label)
          );
        });
    },
    checkBoxOnChange(value) {
      const findIndex = this.form.typesOfPaymentAvailable.findIndex(
        (type) => type == value
      );

      if (findIndex && findIndex >= 0) {
        this.form.typesOfPaymentAvailable.splice(findIndex, 1);
      } else if (findIndex == -1) {
        this.form.typesOfPaymentAvailable.push(value);
      } else this.form.typesOfPaymentAvailable.splice(0, 1);
    },
    resetWindow() {
      Object.assign(this.form, formInitialState());
    },
    clickOpenModalButton(btn, params) {
      btn == "new"
        ? ((this.modal = {
            title: "Yeni Partner Ekle",
            action: "post",
            show: true,
          }),
          this.resetWindow())
        : btn == "old"
        ? ((this.modal = {
            title: "Partner Güncelle",
            action: "put",
            show: true,
          }),
          (this.form = {
            ...params.data,
            typesOfPaymentAvailable: params.data?.typesOfPaymentAvailable
              ?.length
              ? params.data.typesOfPaymentAvailable
              : [],
            isIframe: params.data.isIframe ? params.data.isIframe : false,
            iframeInfo: params.data.iframeInfo
              ? {
                  primaryColor: params.data.iframeInfo.primaryColor || null,
                  secondaryColor: params.data.iframeInfo.secondaryColor || null,
                  infoColor: params.data.iframeInfo.infoColor || null,
                  iFrameUrl: params.data.iframeInfo.iFrameUrl || null,
                  backgroundImgUrl:
                    params.data.iframeInfo.backgroundImgUrl || null,
                  logoUrl: params.data.iframeInfo.logoUrl || null,
                }
              : {
                  primaryColor: null,
                  secondaryColor: null,
                  infoColor: null,
                  iFrameUrl: null,
                  backgroundImgUrl: null,
                  logoUrl: null,
                },
          }))
        : null;
    },
    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(this.searchFilterInput);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
    alert(title, icon) {
      Vue.swal({
        icon: icon ? icon : "success",
        title,
        toast: true,
        position: "bottom-start",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Vue.swal.stopTimer);
          toast.addEventListener("mouseleave", Vue.swal.resumeTimer);
        },
      });
    },
  },
};
</script>
